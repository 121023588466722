module.exports = {
  customer: 'derbyshire',
  environment: 'production',
  yourMoney: true,
  jobsVolunteering: true,
  googleAnalyticsId: 'G-WNK95B63KM',
  robots: false,
  chatWidget: false,
  entitledToBaseUrl: 'https://derbyshirebetteroff.entitledto.co.uk',
  entitledToReassessUrl: 'https://derbyshirebetteroff.entitledto.co.uk/reassessbetteroff',
  requirePostcode: false,

  app: {
    home: 'https://derbyshire.betteroff.org.uk'
  },
  serverBaseUrl: 'https://api-derbyshire.betteroff.org.uk',
  api: {
    baseUrl: 'https://api-derbyshire.betteroff.org.uk/internal/v1'
  },
  authGateway: 'https://account.derbyshire.betteroff.org.uk',
  loginServices: [
    {name: 'kerp'},
    {name: 'phone'},
    {name: 'google', postLogoutUrl: 'https://accounts.google.com/Logout'},
    {name: 'facebook'},
    {name: 'agent'},
    {name: 'referenceNumber'}
  ],
  ui: {
    showJobSearchJournal: true,
    showOfflineForms: true,
    showAssessmentMutators: false,
    restrictions: {
      applications: false
    }
  },
  airbrake: {
    enabled: true,
    projectId: 131082,
    projectKey: 'facc9a060872b357453b677f15d319e3'
  }
};
